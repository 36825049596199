<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :server-items-length="items.length"
      :loading="isLoading"
      :footer-props="{ itemsPerPageOptions: [10, 15, 20, 50, 100] }"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Черный список</v-toolbar-title>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
          icon
          @click="
            selectedItem = item;
            isShownDeleteModal = true;
          "
        >
          <v-icon size="medium">mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="isShownDeleteModal" max-width="500px">
      <template>
        <v-card v-if="selectedItem" class="pa-1">
          <v-card-title
            class="text-h5 text-center justify-center flex-column mb-5"
          >
            Вы точно хотите удалить номер
            <span class="color-accent">
              {{ selectedItem.phone }} — (id: {{ selectedItem.id }})
            </span>
            из черного списка?
          </v-card-title>
          <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
            <v-btn
              color="accent"
              outlined
              @click="
                isShownDeleteModal = false;
                selectedItem = null;
              "
            >
              Нет
            </v-btn>
            <v-btn color="accent" @click="removeFromBlackList">Да</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import api from "@/../api";

export default {
  data() {
    return {
      selectedItem: null,
      isShownDeleteModal: false,
      items: [],
      isLoading: false,
      headers: [
        {
          text: "id",
          align: "start",
          value: "id",
        },
        {
          text: "Номер телефона",
          value: "phone",
          align: "start",
        },
        {
          text: "Кол-во попыток",
          value: "attempts",
          align: "start",
        },
        {
          value: "actions",
          align: "end",
        },
      ],
    };
  },
  async created() {
    await this.setupBlackListData();
  },
  methods: {
    async setupBlackListData() {
      try {
        this.isLoading = true;
        const req = await api.blackList.fetchBlackList();
        this.items = req.data;
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async removeFromBlackList() {
      if (!this.selectedItem) throw new Error("No selected item");
      try {
        await api.blackList.removeFromBlackList(this.selectedItem.id);
        this.$store.dispatch("showSnackbar", {
          msg: `Номер ${this.selectedItem.phone} — (id: ${this.selectedItem.id}) удален из черного списка`,
          color: "success",
          show: true,
        });
        await this.setupBlackListData();
      } catch (e) {
        console.error(e);
      } finally {
        this.isShownDeleteModal = false;
        this.selectedItem = null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.color-accent {
  color: #fc2a6a;
}
</style>
