<template>
  <div>
    <BlackListTable />
  </div>
</template>

<script>
import BlackListTable from "@/components/BlackListTable";

export default {
  name: "BlackList",
  components: { BlackListTable },
};
</script>
